import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes } from "@utils";
import { apiAction } from "@utils/http";

const reducerName = "digiteal";

export const createPreSaleForDigiteal = createAsyncThunk(
  `${reducerName}/create-presale`,
  async (data, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentDigiteal.createAPresale,
        data,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const doDigitealPayment = createAsyncThunk(
  `${reducerName}/do-digiteal-payment`,
  async (presaleId, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentDigiteal.doDigitealPayment,
        urlParams: {
          presale_id: presaleId,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const slice = createSlice({
  name: "digiteal",
  initialState: {
    presale: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPreSaleForDigiteal.pending, (state) => {
      state.presale = null;
      state.success = false;
    });
    builder.addCase(createPreSaleForDigiteal.rejected, (state) => {
      state.presale = null;
      state.success = false;
    });
    builder.addCase(
      createPreSaleForDigiteal.fulfilled,
      (state, { payload }) => {
        state.presale = payload;
        state.success = true;
      }
    );
    builder.addCase(doDigitealPayment.pending, (state) => {
      state.presale = null;
      state.success = false;
    });
    builder.addCase(doDigitealPayment.rejected, (state) => {
      state.presale = null;
      state.success = false;
    });
    builder.addCase(doDigitealPayment.fulfilled, (state, { payload }) => {
      state.presale = payload;
      state.success = true;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;

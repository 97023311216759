import "@assets/scss/style.scss";
import store from "@store";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import InstallPWA from "@components/AddToHomeScreen";
import { BrowserRouter } from "react-router-dom";
import { appStrings, LocalizedRouter } from "@components/I18n";

const bootApp = async () => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <LocalizedRouter
          RouterComponent={BrowserRouter}
          appStrings={appStrings}
        >
          <InstallPWA />
          <App />
        </LocalizedRouter>
      </Provider>
    </StrictMode>,
    document.getElementById("root")
  );
};

bootApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Tooltip } from "@components/Tooltip";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import MediaQuery from "react-responsive";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Progress,
} from "reactstrap";
import { isBoolean } from "lodash";

const RadioCards = ({ items, setSelected, selected, register, errors }) => {
  const intl = useIntl();

  return (
    <FormGroup className={"radio-card-group"}>
      {items.map(
        (
          {
            id,
            name,
            description,
            price,
            availableQuantity,
            offeredQuantity,
            prepaidSaleItemDefinition,
          },
          index
        ) => {
          return (
            <FormGroup
              className={`radio-card ${errors.saleItemId && "error"}`}
              key={index}
            >
              <Label
                onClick={() =>
                  availableQuantity !== 0 && setSelected(`radio-card-${index}`)
                }
              >
                {availableQuantity !== 0 ? (
                  <Input
                    type={"radio"}
                    name={"saleItemId"}
                    onChange={(e) => setSelected(e.currentTarget.value)}
                    value={id}
                    innerRef={register({
                      required: intl.formatMessage({
                        id: "validation.required",
                      }),
                    })}
                  />
                ) : null}
                <div className={"title-group"}>
                  <h3>
                    {name}{" "}
                    <Tooltip
                      content={description}
                      placement={"top"}
                      tooltipId={`tooltip-id-${index}`}
                    >
                      <AiOutlineInfoCircle id={`tooltip-id-${index}`} />
                    </Tooltip>
                  </h3>
                  {prepaidSaleItemDefinition ? (
                    <>
                      <h4>
                        {price !==
                        prepaidSaleItemDefinition.saleItemPrice *
                          prepaidSaleItemDefinition.quantity ? (
                          <>
                            <s>€ {prepaidSaleItemDefinition.saleItemPrice}</s>{" "}
                          </>
                        ) : null}
                        €{" "}
                        {Number.isInteger(
                          price / prepaidSaleItemDefinition.quantity
                        )
                          ? price / prepaidSaleItemDefinition.quantity
                          : (
                              price / prepaidSaleItemDefinition.quantity
                            ).toFixed(2)}
                      </h4>

                      <div className={"bulk"}>
                        <p className={"bulk-price"}>
                          {prepaidSaleItemDefinition.quantity === 1 ? (
                            <FormattedMessage
                              id={`main.webshop.oneFor`}
                              values={{
                                quantity: prepaidSaleItemDefinition.quantity,
                                price: price,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id={`main.webshop.multipleItemsFor`}
                              values={{
                                quantity: prepaidSaleItemDefinition.quantity,
                                price: price,
                              }}
                            />
                          )}
                        </p>
                        {price !==
                        prepaidSaleItemDefinition.saleItemPrice *
                          prepaidSaleItemDefinition.quantity ? (
                          <p className={"bulk-advantage"}>
                            <FormattedMessage
                              id={`main.webshop.advantagePrice`}
                              values={{
                                price:
                                  prepaidSaleItemDefinition.saleItemPrice *
                                    prepaidSaleItemDefinition.quantity -
                                  price,
                              }}
                            />
                          </p>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <h4>€ {price}</h4>
                  )}
                </div>

                {offeredQuantity && availableQuantity ? (
                  <div className={"progress-group"}>
                    <Progress
                      value={offeredQuantity - availableQuantity}
                      max={offeredQuantity}
                      color={"progress-bar"}
                    />
                    <p className={"progress-help-text"}>
                      {`${availableQuantity}/${offeredQuantity} `}{" "}
                      <FormattedMessage id={`main.webshop.items.available`} />
                    </p>
                  </div>
                ) : availableQuantity === 0 ? (
                  <div className={"progress-group"}>
                    <p className={"text-danger"} style={{ lineHeight: 1 }}>
                      <FormattedMessage id={`main.webshop.soldOut`} />
                    </p>
                  </div>
                ) : null}
              </Label>
            </FormGroup>
          );
        }
      )}
    </FormGroup>
  );
};

export default RadioCards;

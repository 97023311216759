import useNotification from "@hooks/useNotification";
import { unwrapResult } from "@reduxjs/toolkit";
import { handleLogin } from "@store/reducers/auth";
import Storage from "@utils/storage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const useLoginForm = () => {
  const dispatch = useDispatch();
  const notify = useNotification();
  const { isLoading } = useSelector((state) => state.auth);
  const { logo } = useSelector((state) => state.theme.images);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const { register, handleSubmit, reset, errors } = useForm(); // initialise the hook

  const handleLoginForm = async (data) => {
    try {
      const token = unwrapResult(await dispatch(handleLogin(data)));
      await Storage.setToken(token);
      setLoggedIn(true);
    } catch (e) {
      notify.error("api.error.login_failed_title", e.error.messageKey);
    }
  };

  return {
    logo,
    register,
    handleSubmit,
    reset,
    errors,
    isLoggedIn,
    isLoading,
    handleLoginForm,
  };
};

export default useLoginForm;

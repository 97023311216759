import useNotification from "@hooks/useNotification";
import { createPreSale } from "@store/reducers/multiSafepay";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useMultiSafepay = ({
  saleItemId,
  reservationId,
  saleItemPrice,
  saleItemQty: quantity,
  identificationItemId = null,
  salePaymentType,
}) => {
  const notify = useNotification();
  const formRef = useRef(null);
  const [render, setRender] = useState(false);
  const multiSafepay = useSelector((state) => {
    const { multiSafepayUrl } = state.multiSafepay;
    return {
      multiSafepayUrl,
    };
  });
  const dispatch = useDispatch();

  useEffect(async () => {
    const response = await dispatch(
      createPreSale({
        saleItemId,
        reservationId,
        saleItemPrice,
        quantity,
        identificationItemId,
        salePaymentTypeCode: salePaymentType,
      })
    );
    if (response.error) {
      notify.error(response.payload.error.message);
    } else {
      setRender(true);
      if (formRef.current) {
        formRef.current.submit();
        if (saleItemId) {
          localStorage.setItem("saleItemId", saleItemId);
        }
        if (reservationId) {
          localStorage.setItem("saleItemReservationId", reservationId);
        }
      }
    }
  }, []);

  return {
    formRef,
    render,
    multiSafepay,
  };
};

export default useMultiSafepay;

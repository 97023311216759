import CompleteAccountRoutes from "@components/Router/CompleteAccountRoutes";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import { Spinner } from "@components/Spinner";
import useRenderCompleteAccount from "@hooks/useRenderCompleteAccount";
import useIosInstallPrompt from "@hooks/useIosInstallPrompt";
import useWebInstallPrompt from "@hooks/useWebInstallPrompt";
import { useEffect } from "react";

const CompleteAccountLayout = () => {
  const { applicationRoutes } = useApplicationRoutes();
  const { render } = useRenderCompleteAccount();

  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  useEffect(() => {
    const installApp = document.querySelector("#install-app");
    const completeAccount = document.querySelector("#complete-account");

    if (installApp && completeAccount) {
      completeAccount.style.paddingTop = `${installApp.offsetHeight}px`;
    }
  }, [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    iosInstallPrompt,
    handleIOSInstallDeclined,
  ]);

  if (!render) return <Spinner />;

  return (
    <div className="complete-account" id={"complete-account"}>
      <CompleteAccountRoutes applicationRoutes={applicationRoutes} />
    </div>
  );
};

export default CompleteAccountLayout;

import { func } from "prop-types";
import { Title } from "@components/Title";
import { Navigation } from "@components/Navigation";
import { forwardRef } from "react";

const Header = forwardRef(
  ({ toggleSidebar, applicationRoutes, sidebarHidden }, ref) => {
    return (
      <header className="nav-sidebar" ref={ref}>
        <Navigation
          toggleSidebar={toggleSidebar}
          sidebarHidden={sidebarHidden}
        />
        <Title applicationRoutes={applicationRoutes} />
      </header>
    );
  }
);

Header.propTypes = {
  toggleSidebar: func.isRequired,
};

export default Header;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";
import { getPreSale } from "@store/reducers/multiSafepay";

const reducerName = "sales";

export const getPaymentProviderAction = createAsyncThunk(
  `${reducerName}/get-payment-provider`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.salePaymentProvider.info,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSalesAction = createAsyncThunk(
  `${reducerName}`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.sale.sales,
        data: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSuccessfulSaleByUuidAction = createAsyncThunk(
  `${reducerName}/get-anonymous-presale`,
  async (uuid, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.sale.getSuccessfulSaleByUuid,
        urlParams: {
          uuid: uuid,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const salesSlice = createSlice({
  name: reducerName,
  initialState: {
    successfulAnonymousSale: {},
    purchaserEmail: "",
    paymentProvider: "",
    saleItemConfigurable: false,
    identificationItemId: null,
    saleItemId: null,
    salePrepaidItem: false,
    saleItemReservationId: null,
    saleItemPrice: null,
    saleItemQty: null,
    pagedSales: {
      sales: [],
      totalElements: 0,
      totalPages: 0,
      size: 10,
      page: 0,
    },
    isLoading: false,
    error: null,
  },
  reducers: {
    setSaleItemData: (state, { payload }) => {
      state.purchaserEmail = payload.purchaserEmail;
      state.saleItemConfigurable = !!payload.saleItemConfigurable;
      state.saleItemId = payload.id;
      state.saleItemPrice = payload.price;
      state.saleItemQty = payload.quantity || 1;
      state.identificationItemId = payload.identificationItemId || null;
      state.saleItemReservationId = payload.saleItemReservationId;
      state.salePrepaidItem = payload.salePrepaidItem;
      state.salePaymentType = payload.salePaymentType;
    },
    setSaleItemDataQuantity: (state, { payload }) => {
      state.saleItemQty = payload.quantity;
    },
    setDefaultSaleItem: (state, { payload }) => {
      state.saleItemConfigurable = payload.saleItemConfigurable || true;
      state.saleItemId = payload.id;
      state.salePrepaidItem = payload.salePrepaidItem || true;
      state.saleItemReservationId = payload.saleItemReservationId;
      state.saleItemPrice = payload.price;
      state.saleItemQty = payload.quantity || 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentProviderAction.pending, (state) => {
      state.paymentProvider = "";
    });
    builder.addCase(getPaymentProviderAction.rejected, (state) => {
      state.paymentProvider = "";
    });
    builder.addCase(
      getPaymentProviderAction.fulfilled,
      (state, { payload }) => {
        state.paymentProvider = payload.name.toLowerCase();
      }
    );
    builder.addCase(getSalesAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSalesAction.rejected, (state, { payload }) => {
      state.pagedSales = {
        sales: [],
      };
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(
      getSalesAction.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.pagedSales = {
          ...state.pagedSales,
          ...payload,
          size: arg.size,
          page: arg.page,
        };
        state.isLoading = false;
      }
    );
    builder.addCase(getSuccessfulSaleByUuidAction.pending, (state) => {
      state.successfulAnonymousSale = "";
    });
    builder.addCase(getSuccessfulSaleByUuidAction.rejected, (state) => {
      state.successfulAnonymousSale = "";
    });
    builder.addCase(
      getSuccessfulSaleByUuidAction.fulfilled,
      (state, { payload }) => {
        state.successfulAnonymousSale = payload;
      }
    );
  },
});

export const { setSaleItemData, setSaleItemDataQuantity, setDefaultSaleItem } =
  salesSlice.actions;

export const actions = salesSlice.actions;

export default salesSlice.reducer;

import coverFallback from "@assets/images/cover.jpg";

const Background = ({ bg }) => {
  return (
    <>
      {bg?.length ? (
        <img
          src={`data:image/png;base64,${bg[0]?.image}`}
          alt={bg[0]?.code}
          className={"cover-image"}
        />
      ) : (
        <img src={coverFallback} alt={"Cover"} className={"cover-image"} />
      )}
    </>
  );
};

export default Background;

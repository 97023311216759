import React from "react";
import { IntlProvider } from "react-intl";
import { Route, Redirect } from "react-router-dom";
import { defaultAppLanguage } from "@const/app-languages";

export const LocalizedRouter = ({ children, RouterComponent, appStrings }) => {
  return (
    <RouterComponent>
      <Route path="/:lang([a-zA-Z]{2})">
        {({ match, location }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match ? match.params : {};
          const { lang = defaultAppLanguage } = params;

          /**
           * If language is not in route path, redirect to language root
           */
          const { pathname, search } = location;

          if (!pathname.includes(`/${lang}`)) {
            return <Redirect to={`/${lang}${pathname}${search}`} />;
          }

          /**
           * Return Intl provider with default language set
           */
          return (
            <IntlProvider
              locale={lang}
              messages={{
                ...appStrings[lang],
                ...appStrings[
                  "override" + lang.charAt(0).toUpperCase() + lang.slice(1)
                ],
              }}
            >
              {children}
            </IntlProvider>
          );
        }}
      </Route>
    </RouterComponent>
  );
};

import { forwardRef } from "react";
import { Nav, NavbarText, NavItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import LocalizedNavLink from "@components/I18n/LocalizedNavLink";
import IntlNumberEUR from "@components/ReactIntl/IntlNumberEUR";
import useNavigation from "@hooks/useNavigation";
import useCredit from "@hooks/useCredit";

const Welcome = forwardRef((props, ref) => {
  const { account, balance } = useNavigation();
  const { saleItems } = useCredit();

  return (
    <div
      className="balance-block d-flex align-items-center justify-content-start flex-wrap"
      ref={ref}
      {...props}
    >
      <NavbarText className="welcome font-18">
        <FormattedMessage
          id="main.sidebar.welcome"
          values={{
            firstName: account.firstName,
            lastName: account.lastName,
          }}
        />
      </NavbarText>
      <>
        <span className="double-pipe font-18 d-none d-md-flex">||</span>
        <Nav navbar>
          <NavItem>
            <LocalizedNavLink
              path="/dashboard/credit"
              isLink={!!saleItems.length}
              className={
                saleItems.length
                  ? "font-18 border-bottom-enlarge d-none d-md-flex"
                  : "font-18 border-bottom-enlarge d-none d-md-flex pe-none"
              }
            >
              <FormattedMessage id="main.navigation.credit" />
            </LocalizedNavLink>
          </NavItem>
          <NavItem>
            <LocalizedNavLink
              path="/dashboard/credit"
              isLink={!!saleItems.length}
              className={
                saleItems.length
                  ? "font-18 d-md-flex"
                  : "font-18 d-md-flex pe-none"
              }
            >
              <NavbarText
                className="badge badge-light font-18 ml-3"
                color="white"
              >
                <IntlNumberEUR value={balance} />
              </NavbarText>
            </LocalizedNavLink>
          </NavItem>
        </Nav>
      </>
    </div>
  );
});

export default Welcome;

import AcceptAgreementCheckbox from "@components/Checkbox/AcceptAgreementCheckbox";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, CardText, CardTitle, Form, FormGroup } from "reactstrap";

const ExtendSubscriptionForm = ({
  subscription,
  saleItemPrice,
  addForm: { register, handleSubmit, errors, handleSubmitForm },
}) => {
  const intl = useIntl();
  return (
    <>
      <CardTitle className="pl-0 pt-3 pb-3 mb-0">
        <FormattedMessage id="main.subscriptions.extendSubscription" />
      </CardTitle>
      <CardText>
        <FormattedMessage id="main.subscriptions.licensePlate" />:{" "}
        {subscription.identificationItemName}
      </CardText>
      <CardText>
        <FormattedMessage id="main.subscriptions.subscriptionDefinitionType" />:{" "}
        {subscription.subscriptionDefinitionDescription}
      </CardText>
      <CardText>
        <FormattedMessage id="main.subscription.price" />: &euro;{" "}
        {saleItemPrice.calculatedPrice}
      </CardText>

      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <FormGroup>
          <AcceptAgreementCheckbox
            agreementMessage={"main.acceptAgreement"}
            agreementUrlText={"main.userAgreementUrl"}
            errors={errors}
            register={register}
            identifier={"agreement"}
          />
        </FormGroup>

        <Button color="primary">
          <FormattedMessage id="main.subscriptions.add" />
        </Button>
      </Form>
    </>
  );
};
export default ExtendSubscriptionForm;

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  changeEmailAddressAction,
  validateToken,
} from "../store/reducers/auth";
import { useParams } from "react-router-dom";

const useConfirmEmailChange = () => {
  const dispatch = useDispatch();
  const { isLoading, validToken, activated } = useSelector(
    (state) => state.auth
  );
  const { logo } = useSelector((state) => state.theme.images);

  const { id } = useParams();

  useEffect(async () => {
    unwrapResult(await dispatch(validateToken({ token: id })));
  }, []);

  useEffect(async () => {
    if (validToken) {
      unwrapResult(await dispatch(changeEmailAddressAction({ token: id })));
    }
  }, [validToken]);

  return {
    logo,
    isLoading,
    validToken,
    activated,
  };
};

export default useConfirmEmailChange;

export const styling = {
  images: {
    logo: "https://placeimg.com/420/120/tech",
  },
  css: {
    children: {
      ":root": {
        children: {},
        attributes: {
          "--primaryBgColor": "#003D73",
          "--primaryTextColor": "#ffffff",
          "--activePrimaryBgColor": "#003D73",
          "--activePrimaryTextColor": "#ffffff",
          "--iconBtnPrimaryBgColor": "#003D73",
          "--iconBtnPrimaryBorderColor": "#003D73",
          "--iconBtnPrimaryTextColor": "#003D73",
          "--btnPrimaryBgColor": "#ffffff",
          "--btnPrimaryBorderColor": "#029EE1",
          "--btnPrimaryColor": "#029EE1",
          "--btnSecondaryBgColor": "#003D73",
          "--btnSecondaryBorderColor": "#003D73",
          "--btnSecondaryColor": "#ffffff",
          "--linkPrimaryColor": "#003D73",
          "--linkSecondaryColor": "#029EE1",
          "--linkActivePrimaryColor": "#029EE1",
          "--breadCrumbsPrimaryColor": "#029EE1",
          "--secondaryBgColor": "#029EE1",
          "--secondaryTextColor": "#ffffff",
          "--badgeLicensePlateBgColor": "#ffffff",
          "--badgeLicensePlateTextColor": "#ba1d1d",
          "--badgeLicensePlateBorderColor": "#ba1d1d",
          "--promoBgColor": "#fa6d15",
          "--promoTextColor": "#ffffff",
          "--pageBgColor": "#eef5f9",
        },
      },
    },
    attributes: {},
  },
};

import LocalizedLink from "@components/I18n/LocalizedLink";
import { LanguageSwitcher } from "@components/I18n";
import React from "react";
import useTenant from "@hooks/useTenant";

const CompactHeader = () => {
  const { logo, logoLoading } = useTenant();

  return (
    <div className={"logo-wrapper"}>
      <div className="img-wrapper" style={{ textAlign: "center" }}>
        {!logoLoading ? (
          <LocalizedLink path="/" className="logo">
            <img src={logo} alt="logo" height={"100px"} />
          </LocalizedLink>
        ) : null}
      </div>
      <div className={"d-flex align-items-center"}>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default CompactHeader;

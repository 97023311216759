import { toastr } from "@components/Toastr";
import { useIntl } from "react-intl";

const useNotification = () => {
  const intl = useIntl();

  const _notify = (type, title, message, toastrOptions, values) => {
    toastr[type](
      intl.formatMessage({ id: title }),
      message ? intl.formatMessage({ id: message }, values) : null,
      toastrOptions
    );
  };

  const success = (title, message = null, options = null, values = null) => {
    _notify("success", title, message, options, values);
  };

  const error = (title, message = null, options = null, values = null) => {
    _notify("error", title, message, options, values);
  };

  const info = (title, message = null, options = null, values = null) => {
    _notify("info", title, message, options, values);
  };

  const warning = (title, message = null, options = null, values = null) => {
    _notify("warning", title, message, options, values);
  };

  const onlyIfContainsError = (response) => {
    if (response?.payload?.error?.messageKey) {
      const { messageKey } = response.payload.error;
      let key = [messageKey];
      if (messageKey.includes("#")) {
        key = messageKey.split("#");
      }

      const values = key[1] ? { key: key[1] } : null;

      error("api.error.failed", key[0], null, values);
    }
  };

  return {
    success,
    error,
    info,
    warning,
    onlyIfContainsError,
  };
};

export default useNotification;

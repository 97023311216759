import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes } from "@utils";
import { apiAction } from "@utils/http";

const reducerName = "multiSafepay";

export const createPreSale = createAsyncThunk(
  `${reducerName}/create-presale`,
  async (data, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.createPresale,
        data,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const createAnonymousPreSale = createAsyncThunk(
  `${reducerName}/create-anonymous-presale`,
  async (data, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.createAnonymousPresale,
        data,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getPreSale = createAsyncThunk(
  `${reducerName}/get-presale`,
  async (transactionId, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.getPresale,
        urlParams: {
          sale_id: transactionId,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

/** THIS WILL ONLY BE USED ON A DEVELOPMENT ENVIRONMENT */
export const processPreSale = createAsyncThunk(
  `${reducerName}/process-presale`,
  async (transactionId, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.processPresale,
        urlParams: {
          transactionid: transactionId,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const slice = createSlice({
  name: "multiSafepay",
  initialState: {
    multiSafepayUrl: "",
    anonymousMultiSafepayUrl: "",
    anonymousSaleUuid: "",
    presale: null,
    processed: false /* only for dev purposes */,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPreSale.pending, (state) => {
      state.multiSafepayUrl = "";
    });
    builder.addCase(createPreSale.rejected, (state) => {
      state.multiSafepayUrl = "";
    });
    builder.addCase(createPreSale.fulfilled, (state, { payload }) => {
      state.multiSafepayUrl = payload.payment_url;
    });
    builder.addCase(createAnonymousPreSale.pending, (state) => {
      state.anonymousMultiSafepayUrl = "";
    });
    builder.addCase(createAnonymousPreSale.rejected, (state) => {
      state.anonymousMultiSafepayUrl = "";
    });
    builder.addCase(createAnonymousPreSale.fulfilled, (state, { payload }) => {
      state.anonymousMultiSafepayUrl = payload.createOrderDataDTO.payment_url;
      state.anonymousSaleUuid = payload.saleUuid;
    });
    builder.addCase(getPreSale.pending, (state) => {
      state.presale = "";
    });
    builder.addCase(getPreSale.rejected, (state) => {
      state.presale = "";
    });
    builder.addCase(getPreSale.fulfilled, (state, { payload }) => {
      state.presale = payload;
    });
    builder.addCase(processPreSale.pending, (state) => {
      state.presale = false;
    });
    builder.addCase(processPreSale.rejected, (state) => {
      state.presale = false;
    });
    builder.addCase(processPreSale.fulfilled, (state) => {
      state.presale = true;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;

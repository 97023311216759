import AuthRoutes from "@components/Router/AuthRoutes";
import React, { useEffect } from "react";
import useWebInstallPrompt from "@hooks/useWebInstallPrompt";
import useIosInstallPrompt from "@hooks/useIosInstallPrompt";
import useTenant from "@hooks/useTenant";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import usePageTitle from "@hooks/usePageTitle";
import { capitalize } from "@utils/helpers";
import { applicationRoutes } from "@utils";

const AuthLayout = () => {
  const { companyName } = useTenant();
  const intl = useIntl();

  const location = useLocation();
  const routes = applicationRoutes.auth;
  const { locale } = useIntl();
  const currentTitle = routes.find(
    (r) =>
      `/${locale}${r.path}` === location.pathname ||
      r?.pathNames?.includes(/[^/]*$/.exec(location.pathname)[0])
  );

  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  useEffect(() => {
    const installApp = document.querySelector("#install-app");
    const auth = document.querySelector("#auth");

    if (installApp && auth) {
      auth.style.paddingTop = `${installApp.offsetHeight}px`;
    }
  }, [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    iosInstallPrompt,
    handleIOSInstallDeclined,
  ]);

  usePageTitle(
    `${companyName}${
      currentTitle?.pageTitle
        ? " - " +
          capitalize(
            intl.formatMessage({
              id: currentTitle?.pageTitle,
            })
          )
        : ""
    }`
  );

  return (
    <div className="auth" id={"auth"}>
      <AuthRoutes />
    </div>
  );
};

export default AuthLayout;

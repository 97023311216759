import Header from "@components/Header/Header";
import DashboardRoutes from "@components/Router/DashboardRoutes";
import { Sidebar } from "@components/Sidebar";
import { Spinner } from "@components/Spinner";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import useRenderDashboard from "@hooks/useRenderDashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import useRenderCompleteAccount from "@hooks/useRenderCompleteAccount";
import useIosInstallPrompt from "@hooks/useIosInstallPrompt";
import useWebInstallPrompt from "@hooks/useWebInstallPrompt";
import usePageTitle from "@hooks/usePageTitle";
import useTenant from "@hooks/useTenant";
import { useIntl } from "react-intl";
import { capitalize } from "@utils/helpers";

const Dashboard = () => {
  const { render, setToggleSidebar, sidebarHidden } = useRenderDashboard();
  const { applicationRoutes, sidebarRender } = useApplicationRoutes();

  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );
  const { push } = useHistory();
  const { render: completeAccountRender } = useRenderCompleteAccount();
  const { companyName } = useTenant();
  const intl = useIntl();

  const location = useLocation();
  const routes = applicationRoutes.dashboard;
  const { locale } = useIntl();
  const currentTitle = routes.find(
    (r) =>
      `/${locale}${r.path}` === location.pathname ||
      r?.pathNames?.includes(/[^/]*$/.exec(location.pathname)[0])
  );

  const account = useSelector((state) => state.profile.account.account);

  useEffect(() => {
    if (account?.languageCode) {
      const npn = location.pathname.replace(
        /[^\/][-a-zA-Z]*/,
        account.languageCode.toLowerCase()
      );
      push(npn + location.search);
    }
  }, [account?.languageCode]);

  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  useEffect(() => {
    const installApp = document.querySelector("#install-app");
    const sidebar = document.querySelector("#sidebarbg");
    const pageWrapper = document.querySelector("#page-wrapper");

    if (installApp && sidebar && pageWrapper) {
      sidebar.style.paddingTop = `${installApp.offsetHeight + 20}px`;
      pageWrapper.style.paddingTop = `${installApp.offsetHeight}px`;
    }
  }, [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    iosInstallPrompt,
    handleIOSInstallDeclined,
  ]);

  useEffect(() => {
    (async () => {
      if (accountStatus === "EMAIL_CONFIRMED" && completeAccountRender) {
        push(
          `/${process.env.REACT_APP_DEFAULT_LOCALE}/complete-personal-details`
        );
      }
    })();
  }, [accountStatus, completeAccountRender]);

  usePageTitle(
    `${companyName} - ${intl.formatMessage({
      id: "main.myAccount",
    })}${
      currentTitle?.pageTitle
        ? " - " +
          capitalize(
            intl.formatMessage({
              id: currentTitle?.pageTitle,
            })
          )
        : ""
    }`
  );

  if (!render || !sidebarRender) return <Spinner />;

  return (
    <>
      <div className="page-wrapper" id={"page-wrapper"}>
        <Sidebar
          status={sidebarHidden}
          applicationRoutes={applicationRoutes}
          toggleSidebar={setToggleSidebar}
        />
        <div className="content">
          <Header
            applicationRoutes={applicationRoutes}
            toggleSidebar={setToggleSidebar}
            sidebarHidden={sidebarHidden}
          />
          <main>
            <DashboardRoutes applicationRoutes={applicationRoutes} />
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

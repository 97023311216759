import { configureStore } from "@reduxjs/toolkit";
import adyen from "@store/reducers/adyen";
// Reducers
import auth from "@store/reducers/auth";
import digiteal from "@store/reducers/digiteal";
import discountVouchers from "@store/reducers/discountVouchers";
import giftVouchers from "@store/reducers/giftVouchers";
import identification from "@store/reducers/identification";
import identificationItemTypes from "@store/reducers/identificationItemTypes";
import ingenico from "@store/reducers/ingenico";
import mandate from "@store/reducers/mandate";
import multiSafepay from "@store/reducers/multiSafepay";
import invoice from "@store/reducers/invoice";
import prepaidSaleItem from "@store/reducers/prepaidSaleItems";
import profile from "@store/reducers/profile";
import saleItemReservations from "@store/reducers/sale-item-reservations";
import saleItem from "@store/reducers/saleItemCategories";
import sales from "@store/reducers/sales";
import subscriptions from "@store/reducers/subscriptions";
import systemImages from "@store/reducers/systemImages";
import systemSetting from "@store/reducers/systemSetting";
import tenant from "@store/reducers/tenant";
import theme from "@store/reducers/theme";
import tickets from "@store/reducers/tickets";
import { reducer as toastr } from "react-redux-toastr";
import { combineReducers } from "redux";

// Root Reducer
const rootReducer = combineReducers({
  adyen,
  auth,
  digiteal,
  discountVouchers,
  giftVouchers,
  identification,
  identificationItemTypes,
  ingenico,
  invoice,
  prepaidSaleItem,
  profile,
  mandate,
  multiSafepay,
  saleItem,
  saleItemReservations,
  sales,
  subscriptions,
  systemImages,
  systemSetting,
  tenant,
  theme,
  tickets,
  toastr,
});

const initStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
};

export default initStore();

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "subscriptions";

export const getSubscriptionsAction = createAsyncThunk(
  `${reducerName}/get-subscriptions`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.subscriptions,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSubscriptionAction = createAsyncThunk(
  `${reducerName}/get-subscription`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.subscription,
        urlParams: params,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const addSubscriptionAction = createAsyncThunk(
  `${reducerName}/create-subscription`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.createSubscription,
        data: body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const stopAction = createAsyncThunk(
  `${reducerName}/stop-subscription`,
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.stopSubscription,
        urlParams: { subscriptionId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const renewAction = createAsyncThunk(
  `${reducerName}/renew-subscription`,
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.reactivateSubscription,
        urlParams: { subscriptionId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getMonthlyTotalAmountToPayForSubscriptionsAction =
  createAsyncThunk(
    `${reducerName}/get-monthly-total-amount-to-pay-for-subscriptions`,
    async (_, thunkAPI) => {
      try {
        const { data } = await apiAction({
          ...apiRoutes.subscription
            .getMonthlyTotalAmountToPayForSubscriptionsAction,
        });
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error });
      }
    }
  );

const subscriptionSlice = createSlice({
  name: reducerName,
  initialState: {
    list: [],
    data: {},
    isLoading: false,
    error: null,
    totalAmount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSubscriptionAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addSubscriptionAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addSubscriptionAction.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSubscriptionsAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSubscriptionsAction.rejected, (state, { payload }) => {
      state.list = [];
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(getSubscriptionsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.list = payload;
    });
    builder.addCase(getSubscriptionAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSubscriptionAction.rejected, (state, { payload }) => {
      state.data = [];
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(getSubscriptionAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(
      getMonthlyTotalAmountToPayForSubscriptionsAction.pending,
      (state) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      getMonthlyTotalAmountToPayForSubscriptionsAction.rejected,
      (state, { payload }) => {
        state.list = [];
        state.isLoading = false;
        state.error = payload;
      }
    );
    builder.addCase(
      getMonthlyTotalAmountToPayForSubscriptionsAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.totalAmount = payload.totalAmount;
      }
    );
  },
});

export const actions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

const localCache = localStorage;

class StorageClass {
  constructor() {
    this.AUTH_KEY = "auth";
    this.ANONYMOUS_MULTISAFEPAY_SALE_UUID = "anonymousMultisafepaySaleUuid";
  }

  getItem(key) {
    return new Promise((resolve, reject) => {
      const _value = localCache.getItem(key);
      return _value ? resolve(_value) : reject("error.storage.notfound");
    });
  }

  setItem(key, value) {
    return new Promise((resolve, reject) => {
      try {
        localCache.setItem(key, value);
        resolve(value);
      } catch (e) {
        reject("error.storage.notstored");
      }
    });
  }

  removeItem(key) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(key);
      resolve(key);
    });
  }

  clear() {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      resolve(true);
    });
  }

  setToken(token) {
    return this.setItem(this.AUTH_KEY, token);
  }

  getToken() {
    return this.getItem(this.AUTH_KEY);
  }

  setAnonymousMultisafepaySaleUuid(uuid) {
    return this.setItem(this.ANONYMOUS_MULTISAFEPAY_SALE_UUID, uuid);
  }

  getAnonymousMultisafepaySaleUuid() {
    return this.getItem(this.ANONYMOUS_MULTISAFEPAY_SALE_UUID);
  }
}

const Storage = new StorageClass();

export default Storage;

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const useCheckout = () => {
  const paymentProvider = useSelector((state) => state.sales.paymentProvider);
  const history = useHistory();
  const { locale } = useIntl();

  const handleCheckout = () => {
    history.push(`/${locale}/dashboard/${paymentProvider}/checkout`);
  };

  return {
    handleCheckout,
  };
};

export default useCheckout;

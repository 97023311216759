import { forwardRef } from "react";
import { NavbarText } from "reactstrap";
import { FormattedMessage } from "react-intl";

const AnonymousWelcome = forwardRef((props, ref) => {
  return (
    <div
      className="balance-block d-flex align-items-center justify-content-start flex-wrap"
      ref={ref}
      {...props}
    >
      <NavbarText className="welcome font-18">
        <FormattedMessage id="main.sidebar.anonymousWelcome" />
      </NavbarText>
    </div>
  );
});

export default AnonymousWelcome;

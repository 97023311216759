import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

export const IDENTIFICATION_ITEM_TYPE_ANPR = "ANPR";
export const IDENTIFICATION_ITEM_TYPE_RFID = "RFID";
export const IDENTIFICATION_ITEM_TYPE_BARCODE = "BARCODE";

const reducerName = "identification";

export const getLicensePlatesAction = createAsyncThunk(
  `${reducerName}/license-plates`,
  async (identificationItemTypeCode, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.identificationItems,
        data: {
          identificationItemTypeCode: IDENTIFICATION_ITEM_TYPE_ANPR,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getCleaningCardsAction = createAsyncThunk(
  `${reducerName}/clean-cards`,
  async (identificationItemTypeCode, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.identificationItems,
        data: {
          identificationItemTypeCode,
        },
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getCleaningCardCreationRequestsAction = createAsyncThunk(
  `${reducerName}/clean-card-creation-requests`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.identificationItemCreationRequests,
        data: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

// TODO :: Refactor naar de giftVoucherReducer
export const registerGiftVouchersAction = createAsyncThunk(
  `${reducerName}/register-gift-voucher-item`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.registerIdentificationItem,
        data: body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const addCleaningCardAction = createAsyncThunk(
  `${reducerName}/register-identification-item`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.registerIdentificationItem,
        data: body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const addCleaningCardCreationRequestAction = createAsyncThunk(
  `${reducerName}/create-identification-item-creation-requests`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.createIdentificationItemCreationRequest,
        data: body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const addLicensePlateAction = createAsyncThunk(
  `${reducerName}/create-license-plate`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.createIdentificationItem,
        data: body,
      });

      return data;
    } catch (error) {
      if (error.messageKey === "exception.regex.validation.name") {
        error = {
          message: "",
          messageKey: "exception.licenseplate.validation",
        };
      }
      return rejectWithValue({ error });
    }
  }
);

export const editIdentificationItemAction = createAsyncThunk(
  `${reducerName}/edit-identification-item`,
  async (body, { rejectWithValue }) => {
    if (body.restrictedSaleItemIds === undefined) {
      body.restrictedSaleItemIds = [];
    }
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.editIdentificationItem,
        data: body,
        urlParams: { identificationItemId: body.id },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const disableLicensePlateAction = createAsyncThunk(
  `${reducerName}/disable-license-plate`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.disableIdentificationItem,
        urlParams: { identificationItemId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const enableLicensePlateAction = createAsyncThunk(
  `${reducerName}/enable-license-plate`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.enableIdentificationItem,
        urlParams: { identificationItemId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const disableCleanCardAction = createAsyncThunk(
  `${reducerName}/disable-clean-card`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.disableIdentificationItem,
        urlParams: { identificationItemId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const enableCleanCardAction = createAsyncThunk(
  `${reducerName}/enable-clean-card`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItem.enableIdentificationItem,
        urlParams: { identificationItemId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const cancelCleaningCardCreationRequestAction = createAsyncThunk(
  `${reducerName}/cancel-cleaning-card-creation-request`,
  async (cleaningCardCreationRequestId, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.identificationItem.cancelIdentificationItemCreationRequest,
        urlParams: {
          identificationItemCreationRequestId: cleaningCardCreationRequestId,
        },
      });
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const unlinkCleaningCardItemAction = createAsyncThunk(
  `${reducerName}/unlink-all-cleaning-card`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.identificationItem.unlinkAllIdentificationItem,
        urlParams: { identificationItemId },
      });
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const unlinkLicensePlateAction = createAsyncThunk(
  `${reducerName}/unlink-all-license-plate`,
  async (identificationItemId, { rejectWithValue }) => {
    try {
      await apiAction({
        ...apiRoutes.identificationItem.unlinkAllIdentificationItem,
        urlParams: { identificationItemId },
      });
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const identificationSlice = createSlice({
  name: reducerName,
  initialState: {
    licensePlates: {
      data: [],
      isLoading: false,
      error: null,
    },
    cleanCards: {
      data: [],
      isLoading: false,
      error: null,
    },
    cleanCardCreationRequestIsLoading: false,
    cleanCardCreationRequests: {
      data: [],
      isLoading: false,
      error: null,
    },
    pagedCleanCardCreationRequests: {
      cleanCardCreationRequests: [],
      totalElements: 0,
      totalPages: 0,
      size: 10,
      page: 0,
    },
    // TODO :: Refactor naar de giftVoucherReducer
    giftVoucher: {
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addLicensePlateAction.pending, (state) => {
      state.licensePlates.isLoading = true;
    });
    builder.addCase(addLicensePlateAction.rejected, (state) => {
      state.licensePlates.isLoading = false;
    });
    builder.addCase(addLicensePlateAction.fulfilled, (state) => {
      state.licensePlates.isLoading = false;
    });
    builder.addCase(addCleaningCardAction.pending, (state) => {
      state.cleanCards.isLoading = true;
    });
    builder.addCase(addCleaningCardAction.rejected, (state) => {
      state.cleanCards.isLoading = false;
    });
    builder.addCase(addCleaningCardAction.fulfilled, (state) => {
      state.cleanCards.isLoading = false;
    });
    builder.addCase(addCleaningCardCreationRequestAction.pending, (state) => {
      state.cleanCardCreationRequests.isLoading = true;
    });
    builder.addCase(addCleaningCardCreationRequestAction.rejected, (state) => {
      state.cleanCardCreationRequests.isLoading = false;
    });
    builder.addCase(addCleaningCardCreationRequestAction.fulfilled, (state) => {
      state.cleanCardCreationRequests.isLoading = false;
    });
    builder.addCase(unlinkLicensePlateAction.pending, (state) => {
      state.licensePlates.isLoading = true;
    });
    builder.addCase(unlinkLicensePlateAction.rejected, (state) => {
      state.licensePlates.isLoading = false;
    });
    builder.addCase(unlinkLicensePlateAction.fulfilled, (state) => {
      state.licensePlates.isLoading = false;
    });
    builder.addCase(unlinkCleaningCardItemAction.pending, (state) => {
      state.cleanCards.isLoading = true;
    });
    builder.addCase(unlinkCleaningCardItemAction.rejected, (state) => {
      state.cleanCards.isLoading = false;
    });
    builder.addCase(unlinkCleaningCardItemAction.fulfilled, (state) => {
      state.cleanCards.isLoading = false;
    });
    builder.addCase(registerGiftVouchersAction.pending, (state) => {
      state.giftVoucher.loading = true;
    });
    builder.addCase(registerGiftVouchersAction.rejected, (state) => {
      state.giftVoucher.loading = false;
    });
    builder.addCase(registerGiftVouchersAction.fulfilled, (state) => {
      state.giftVoucher.loading = false;
    });
    builder.addCase(getLicensePlatesAction.pending, (state) => {
      state.licensePlates.isLoading = true;
      state.licensePlates.error = null;
    });
    builder.addCase(getLicensePlatesAction.rejected, (state, { payload }) => {
      state.licensePlates.data = [];
      state.licensePlates.isLoading = false;
      state.licensePlates.error = payload;
    });
    builder.addCase(getLicensePlatesAction.fulfilled, (state, { payload }) => {
      state.licensePlates.isLoading = false;
      state.licensePlates.data = payload.map((item) => {
        item.disabled = false;
        return item;
      });
    });
    builder.addCase(getCleaningCardsAction.pending, (state) => {
      state.cleanCards.isLoading = true;
      state.cleanCards.error = null;
    });
    builder.addCase(getCleaningCardsAction.rejected, (state, { payload }) => {
      state.cleanCards.data = [];
      state.cleanCards.isLoading = false;
      state.cleanCards.error = payload;
    });
    builder.addCase(getCleaningCardsAction.fulfilled, (state, { payload }) => {
      state.cleanCards.isLoading = false;
      state.cleanCards.data = payload.map((item) => {
        item.disabled = false;
        return item;
      });
    });
    builder.addCase(getCleaningCardCreationRequestsAction.pending, (state) => {
      state.cleanCardCreationRequests.cleanCardCreationRequestIsLoading = true;
      state.cleanCardCreationRequests.error = null;
    });
    builder.addCase(
      getCleaningCardCreationRequestsAction.rejected,
      (state, { payload }) => {
        state.pagedCleanCardCreationRequests = {
          cleanCardCreationRequests: [],
        };
        state.cleanCardCreationRequestIsLoading = false;
        state.error = payload;
      }
    );
    builder.addCase(
      getCleaningCardCreationRequestsAction.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.pagedCleanCardCreationRequests = {
          ...state.pagedCleanCardCreationRequests,
          ...payload,
          size: arg.size,
          page: arg.page,
        };
        state.cleanCardCreationRequestIsLoading = false;
      }
    );
    builder.addCase(
      enableLicensePlateAction.pending,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          disabled: true,
        };
      }
    );
    builder.addCase(
      enableLicensePlateAction.rejected,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          disabled: false,
        };
      }
    );
    builder.addCase(
      enableLicensePlateAction.fulfilled,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          status: "ENABLED",
          disabled: false,
        };
      }
    );
    builder.addCase(
      disableLicensePlateAction.pending,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          disabled: true,
        };
      }
    );
    builder.addCase(
      disableLicensePlateAction.rejected,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          disabled: false,
        };
      }
    );
    builder.addCase(
      disableLicensePlateAction.fulfilled,
      (state, { meta: { arg } }) => {
        const itemIndex = state.licensePlates.data.findIndex(
          (item) => item.id === arg
        );
        state.licensePlates.data[itemIndex] = {
          ...state.licensePlates.data[itemIndex],
          status: "DISABLED",
          disabled: false,
        };
      }
    );
    builder.addCase(
      enableCleanCardAction.pending,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          disabled: true,
        };
      }
    );
    builder.addCase(
      enableCleanCardAction.rejected,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          disabled: false,
        };
      }
    );
    builder.addCase(
      enableCleanCardAction.fulfilled,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          status: "ENABLED",
          disabled: false,
        };
      }
    );
    builder.addCase(
      disableCleanCardAction.pending,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          disabled: true,
        };
      }
    );
    builder.addCase(
      disableCleanCardAction.rejected,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          disabled: false,
        };
      }
    );
    builder.addCase(
      disableCleanCardAction.fulfilled,
      (state, { meta: { arg } }) => {
        const itemIndex = state.cleanCards.data.findIndex(
          (item) => item.id === arg
        );
        state.cleanCards.data[itemIndex] = {
          ...state.cleanCards.data[itemIndex],
          status: "DISABLED",
          disabled: false,
        };
      }
    );
  },
});

export const actions = identificationSlice.actions;

export default identificationSlice.reducer;

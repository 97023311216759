import InvoiceData from "@components/Credit/InvoiceData";
import PersonalData from "@components/Credit/PersonalData";
import { Spinner } from "@components/Spinner";
import ExtendSubscriptionForm from "@components/Subscriptions/ExtendSubscriptionForm";
import useExtendSubscriptions from "@hooks/useExtendSubscriptions";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const ExtendSubscription = () => {
  const {
    data,
    render,
    saleItemPrice,
    account,
    invoiceData,
    invoiceDataError,
    addForm,
  } = useExtendSubscriptions();
  if (!render) return <Spinner />;
  return (
    <>
      <div className="Subscriptions">
        <Row className="mb-3">
          <Col className="mt-3" lg="4" md="12">
            <Card>
              <CardBody>
                <ExtendSubscriptionForm
                  subscription={data}
                  saleItemPrice={saleItemPrice}
                  addForm={addForm}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="mt-3" lg="4" md="12">
            <Card>
              <CardBody className="personal-data">
                <PersonalData
                  accountDetails={account}
                  invoiceData={invoiceData}
                  invoiceDataError={invoiceDataError}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="mt-3" lg="4" md="12">
            <Card>
              <CardBody className="invoice-data">
                <InvoiceData
                  invoiceData={invoiceData}
                  invoiceDataError={invoiceDataError}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExtendSubscription;

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { myCustomerInvoiceData } from "@store/reducers/profile";
import { unwrapResult } from "@reduxjs/toolkit";

const CATEGORY_CODE_CHARGE = "CHARGE";

const useCredit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const saleItems = useSelector(
    (state) => state.saleItem.list[CATEGORY_CODE_CHARGE]
  );
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );
  const { account, invoiceData } = useSelector((state) => state.profile);
  const [renderCredit, setRenderCredit] = useState(false);

  useEffect(async () => {
    if (accountStatus === "COMPLETED") {
      await dispatch(myCustomerInvoiceData())
        .then(unwrapResult)
        .catch(
          (rejectedValueOrSerializedError) => rejectedValueOrSerializedError
        );

      setRenderCredit(true);
    }
  }, [dispatch, accountStatus]);

  return {
    saleItems: saleItems || [],
    account: account.account,
    invoiceData: invoiceData.invoiceData,
    setRenderCredit,
    intl,
    renderCredit,
  };
};

export default useCredit;

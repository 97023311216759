import { useEffect, useState } from "react";
import { getPaymentProviderAction } from "@store/reducers/sales";
import { useDispatch } from "react-redux";

const useAnonymousRenderDashboard = () => {
  const [sidebarHidden, setSidebarVisibility] = useState(true);
  const [render, setRenderDashboard] = useState(false);
  const dispatch = useDispatch();

  const setRender = (isRender) => setRenderDashboard(isRender);

  const setToggleSidebar = () => setSidebarVisibility(!sidebarHidden);

  useEffect(() => {
    (async () => {
      await dispatch(getPaymentProviderAction());
    })();
  }, []);

  return {
    setToggleSidebar,
    sidebarHidden,
    setRender,
    render,
  };
};

export default useAnonymousRenderDashboard;

import useApplicationRoutes from "@hooks/useApplicationRoutes";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePageTitle from "@hooks/usePageTitle";
import { useIntl } from "react-intl";
import { capitalize } from "@utils/helpers";
import AnonymousDashboardRoutes from "@components/Router/AnonymousDashboardRoutes";
import useRenderAnonymousDashboard from "@hooks/useRenderAnonymousDashboard";
import AnonymousSidebar from "@components/AnonymousSidebar/AnonymousSidebar";
import AnonymousHeader from "@components/AnonymousHeader/AnonymousHeader";
import useIosInstallPrompt from "@hooks/useIosInstallPrompt";
import useWebInstallPrompt from "@hooks/useWebInstallPrompt";

const AnonymousDashboardLayout = () => {
  const { applicationRoutes } = useApplicationRoutes();
  const { setToggleSidebar, sidebarHidden } = useRenderAnonymousDashboard();
  const intl = useIntl();

  const location = useLocation();
  const routes = applicationRoutes.anonymousDashboard;
  const { locale } = useIntl();
  const currentTitle = routes.find(
    (r) =>
      `/${locale}${r.path}` === location.pathname ||
      r?.pathNames?.includes(/[^/]*$/.exec(location.pathname)[0])
  );

  usePageTitle(
    `${intl.formatMessage({
      id: "main.myAccount",
    })}${
      currentTitle?.pageTitle
        ? " - " +
          capitalize(
            intl.formatMessage({
              id: currentTitle?.pageTitle,
            })
          )
        : ""
    }`
  );

  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  useEffect(() => {
    const installApp = document.querySelector("#install-app");
    const sidebar = document.querySelector("#sidebarbg");
    const pageWrapper = document.querySelector("#page-wrapper");

    if (installApp && sidebar && pageWrapper) {
      sidebar.style.paddingTop = `${installApp.offsetHeight + 20}px`;
      pageWrapper.style.paddingTop = `${installApp.offsetHeight}px`;
    }
  }, [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    iosInstallPrompt,
    handleIOSInstallDeclined,
  ]);

  return (
    <>
      <div className="page-wrapper" id={"page-wrapper"}>
        <AnonymousSidebar
          status={sidebarHidden}
          applicationRoutes={applicationRoutes}
          toggleSidebar={setToggleSidebar}
        />
        <div className="content">
          <AnonymousHeader
            applicationRoutes={applicationRoutes}
            toggleSidebar={setToggleSidebar}
            sidebarHidden={sidebarHidden}
          />
          <main>
            <AnonymousDashboardRoutes applicationRoutes={applicationRoutes} />
          </main>
        </div>
      </div>
    </>
  );
};

export default AnonymousDashboardLayout;

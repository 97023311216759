import ReduxToastr from "react-redux-toastr";

const Toastr = () => (
  <ReduxToastr
    timeOut={8000}
    newestOnTop={false}
    preventDuplicates
    position="top-right"
    getState={(state) => state.toastr}
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    closeOnToastrClick
  />
);

export default Toastr;

import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useIntl } from "react-intl";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const { locale } = useIntl();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn &&
        props.match.path === `/${locale}/` &&
        props.match.isExact ? (
          <>
            <Redirect to={`/${locale}/dashboard`} /> <Component {...props} />
          </>
        ) : isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/${locale}/auth/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;

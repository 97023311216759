import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const LocalizedLink = ({ path, state, className, style, children }) => {
  const { locale } = useIntl();

  return (
    <Link
      to={{ pathname: `/${locale}${path}`, state: state }}
      className={className}
      style={style}
    >
      {children}
    </Link>
  );
};

export default LocalizedLink;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "gift-vouchers";

export const getGiftVouchersAction = createAsyncThunk(
  `${reducerName}`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.giftVouchers.giftVouchers,
        data: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getGiftVoucherById = createAsyncThunk(
  `${reducerName}-by-id`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.giftVouchers.giftVouchersPdf,
        urlParams: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const giftVouchersSlice = createSlice({
  name: reducerName,
  initialState: {
    list: {
      data: [],
      isLoading: false,
      error: null,
    },
    create: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGiftVouchersAction.pending, (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    });
    builder.addCase(getGiftVouchersAction.rejected, (state, { payload }) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = payload;
    });
    builder.addCase(getGiftVouchersAction.fulfilled, (state, { payload }) => {
      state.list.isLoading = false;
      state.list.data = payload;
    });
  },
});

export const actions = giftVouchersSlice.actions;

export default giftVouchersSlice.reducer;

import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

const LocalizedNavLink = ({
  path,
  state,
  isLink = true,
  className,
  style,
  children,
}) => {
  const { locale } = useIntl();

  return (
    <NavLink
      to={isLink ? { pathname: `/${locale}${path}`, state: state } : ""}
      className={className}
      style={style}
      exact
    >
      {children}
    </NavLink>
  );
};

export default LocalizedNavLink;

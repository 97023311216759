import { FormattedNumber } from "react-intl";

const IntlNumberEUR = (props) => (
  <FormattedNumber
    style="currency"
    minimumFractionDigits={2}
    maximumFractionDigits={2}
    currencyDisplay="symbol"
    currency="EUR"
    value={props.value}
  />
);
export default IntlNumberEUR;

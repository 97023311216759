import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "system-setting";

export const getSystemSettingAction = createAsyncThunk(
  `${reducerName}/get-system-setting`,
  async (_, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.systemSetting.getSystemSetting,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const systemSettingSlice = createSlice({
  name: reducerName,
  initialState: {
    settings: {},
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSystemSettingAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSystemSettingAction.rejected, (state, { payload }) => {
      state.settings = null;
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(getSystemSettingAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.settings = payload;
    });
  },
});

export const actions = systemSettingSlice.actions;

export default systemSettingSlice.reducer;

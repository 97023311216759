import { Suspense } from "react";
import { Route } from "react-router-dom";
import { Spinner } from "@components/Spinner";
import NotFound from "./NotFound";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";
import { useSelector } from "react-redux";
import AccountNotVerified from "@components/AccountNotVerified/AccountNotVerified";

const DashboardRoutes = ({ applicationRoutes }) => {
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );

  if (accountStatus === "NEW") {
    applicationRoutes.dashboard.map((route) => {
      route.component = AccountNotVerified;
      return route;
    });
  }

  return (
    <Suspense fallback={<Spinner />}>
      <LocalizedSwitch>
        {applicationRoutes.dashboard.map((route) => (
          <Route {...route} key={route.path} />
        ))}
        <Route component={NotFound} />
      </LocalizedSwitch>
    </Suspense>
  );
};

export default DashboardRoutes;

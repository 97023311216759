import useNavigation from "@hooks/useNavigation";
import useSidebar from "@hooks/useSidebar";
import { AiOutlineUser } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router-dom";
import { Nav } from "reactstrap";
import useTenant from "../../hooks/useTenant";
import LocalizedLink from "@components/I18n/LocalizedLink";
import LocalizedNavLink from "@components/I18n/LocalizedNavLink";

const AnonymousSidebar = (props) => {
  const { logo } = useTenant();
  const { scrollTop } = useSidebar(props);
  const { logout } = useNavigation(props);
  const { applicationRoutes } = props;

  const mapLinks = applicationRoutes.anonymousDashboard
    ?.filter((route) => route.visibleOnSidebar)
    .map((prop) => {
      return (
        <li onClick={scrollTop} key={prop.path} className="first-level">
          <LocalizedNavLink
            path={prop.path}
            state={{ breadCrumbs: prop.path }}
            className="sidebar-link"
            activeClassName="active"
            style={{ width: "150px" }}
          >
            {prop.sidebarIcon}
            <span className="hide-menu">
              <FormattedMessage id={prop.sidebarName} />
            </span>
          </LocalizedNavLink>
        </li>
      );
    });

  return (
    <aside
      className={
        props.status && matchMedia("only screen and (max-width: 764px)").matches
          ? "left-sidebar hidden"
          : "left-sidebar"
      }
      id="sidebarbg"
      data-sidebarbg="skin6"
    >
      <div className="left-sidebar-inner">
        <MediaQuery query="(max-device-width: 764px)">
          <i onClick={props.toggleSidebar} className="ti-close ti-close" />
        </MediaQuery>

        <LocalizedLink path="/guest/webshop" className="logo">
          <img src={logo} alt="logo" />
        </LocalizedLink>

        <div className="scroll-sidebar">
          <div
            className="scrollbar-container sidebar-nav ps--active-y"
            style={{ position: "absolute", height: "100%" }}
          >
            <Nav id="sidebarnav" className="selected sidebar-item">
              {mapLinks}
            </Nav>

            <div
              onClick={scrollTop}
              className="nav-additional"
              style={{ border: "0px", bottom: "25px", position: "fixed" }}
            >
              <div onClick={logout} className={"d-flex align-items-center"}>
                <AiOutlineUser />
                <span>
                  <FormattedMessage id="main.navigation.signin" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
export default withRouter(AnonymousSidebar);

import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import useIosInstallPrompt from "@hooks/useIosInstallPrompt";
import useWebInstallPrompt from "@hooks/useWebInstallPrompt";
import { FormattedMessage } from "react-intl";
import { IoShareOutline } from "react-icons/io5";
import useApp from "@hooks/useApp";

const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  const [visibleIOSState, setVisibleIOSState] = useState(false);
  const [visibleWebState, setVisibleWebState] = useState(false);
  const { bootApp, projectStyling } = useApp();

  useEffect(() => {
    if (iosInstallPrompt) {
      setVisibleIOSState(true);
    } else if (webInstallPrompt) {
      setVisibleWebState(true);
    }
  }, [iosInstallPrompt, webInstallPrompt]);

  const closeIOSInstallPrompt = () => {
    handleIOSInstallDeclined();

    const sidebar = document.querySelector("#sidebarbg");
    const pageWrapper = document.querySelector("#page-wrapper");
    const auth = document.querySelector("#auth");

    if (sidebar) {
      sidebar.style.paddingTop = `20px`;
    }

    if (pageWrapper) {
      pageWrapper.style.paddingTop = `0px`;
    }

    if (auth) {
      auth.style.paddingTop = `0px`;
    }
  };

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <>
      {bootApp && iosInstallPrompt && (
        <Alert
          color="info"
          isOpen={visibleIOSState}
          toggle={closeIOSInstallPrompt}
          className={"install-app"}
          id={"install-app"}
        >
          <div className={"d-flex flex-column align-items-center"}>
            <h6 className={"font-weight-bold text-white w-100"}>
              <FormattedMessage id="main.app.ios.info.title" />
            </h6>
            <div>
              <span className={"mb-0 mr-2 font-weight-bolder w-auto"}>
                <FormattedMessage id="main.app.ios.info.tap" />
              </span>
              <IoShareOutline
                className={"d-inline-block mr-2 font-16 w-auto"}
              />
              <span className={"mb-0 font-weight-bolder w-auto"}>
                <FormattedMessage id="main.app.ios.info.text" />
              </span>
            </div>
          </div>
        </Alert>
      )}
      {bootApp && webInstallPrompt && (
        <Alert
          color="info"
          isOpen={visibleWebState}
          toggle={handleWebInstallDeclined}
          className={"install-app"}
          id={"install-app"}
        >
          <div className={"d-flex align-items-center justify-content-center"}>
            <p className={"mb-0 mr-2"}>
              <FormattedMessage id="main.app.web.info.title" />
            </p>
            <button
              className={"btn btn-primary"}
              onClick={handleWebInstallAccepted}
            >
              <FormattedMessage id="main.app.web.info.cta" />
            </button>
          </div>
        </Alert>
      )}
    </>
  );
};

export default InstallPWA;

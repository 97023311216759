import { withRouter } from "react-router-dom";
import { Navbar } from "reactstrap";
import useTenant from "@hooks/useTenant";
import LocalizedLink from "@components/I18n/LocalizedLink";
import { FiMenu, FiX } from "react-icons/fi";
import Welcome from "@components/Welcome";

const Navigation = (props) => {
  const { logo } = useTenant();

  return (
    <Navbar expand="md">
      <div
        className={
          "d-flex justify-content-between align-items-center w-100 py-2 py-md-0"
        }
      >
        <LocalizedLink path="/dashboard" className="logo d-block d-md-none">
          <img src={logo} alt="logo" />
        </LocalizedLink>
        <a
          href="#"
          className="nav-toggler d-block d-md-none"
          onClick={props.toggleSidebar}
        >
          {props.sidebarHidden ? (
            <FiMenu className={"font-16"} />
          ) : (
            <FiX className={"font-16"} />
          )}
        </a>
      </div>
      <Welcome />
    </Navbar>
  );
};
export default withRouter(Navigation);

import { useSelector } from "react-redux";

const useIdentificationItemType = () => {
  const { data } = useSelector((state) => state.identificationItemTypes);

  return {
    data,
  };
};
export default useIdentificationItemType;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "tickets";

export const createInvoiceForTicket = createAsyncThunk(
  `${reducerName}/create-invoice-for-ticket`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.ticket.createInvoiceForTicket,
        data: body,
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getTicketById = createAsyncThunk(
  `${reducerName}-by-id`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.ticket.downloadTicket,
        urlParams: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const ticketsSlice = createSlice({
  name: reducerName,
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createInvoiceForTicket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInvoiceForTicket.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createInvoiceForTicket.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const actions = ticketsSlice.actions;

export default ticketsSlice.reducer;

import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const DigitealInfo = () => {
  return (
    <Row className={"mx-0"}>
      <Col className="mt-3" md="12">
        <Card>
          <CardBody>
            <>
              <CardTitle className="mb-3">
                <FormattedMessage id={`digiteal.payment.initiated.title`} />
              </CardTitle>
              <span>
                <FormattedMessage
                  id={`digiteal.payment.initiated.information`}
                />
              </span>
            </>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DigitealInfo;

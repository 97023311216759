import { getIdentificationItemTypes } from "@store/reducers/identificationItemTypes";
import { myCustomerInvoiceData } from "@store/reducers/profile";
import { getSaleItems } from "@store/reducers/saleItemCategories";
import { myPrepaidSaleItems } from "@store/reducers/prepaidSaleItems";
import { applicationRoutes } from "@utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useApplicationRoutes = () => {
  const dispatch = useDispatch();
  const [appRoutes, setAppRoutes] = useState(applicationRoutes);
  const isAdded = useSelector((state) => state.profile.invoiceData.isAdded);
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );
  const [sidebarRender, setSidebarRender] = useState(false);
  const CATEGORY_CODE_SUBSCRIPTIONS = "SUBSCRIPTION";
  const CATEGORY_CODE_CHARGE = "CHARGE";
  const CATEGORY_CODE_GIFT = "GIFT";
  const CATEGORY_CODE_PREPAID = "PREPAID";

  useEffect(() => {
    (async () => {
      let filteredAppRoutes = applicationRoutes.dashboard;

      if (accountStatus === "COMPLETED") {
        await dispatch(myCustomerInvoiceData()).then(({ payload }) => {
          if (!payload) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/invoice`
            );
          } else {
            const inVoiceRoute = filteredAppRoutes.find(
              (route) => route.path === `/dashboard/invoice`
            );
            inVoiceRoute.visibleOnSidebar = true;
          }
        });

        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_CHARGE })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/credit`
            );
          }
        });

        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_SUBSCRIPTIONS })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/subscriptions`
            );
          }
        });

        await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_GIFT })).then(
          ({ payload }) => {
            if (!payload.data.length) {
              filteredAppRoutes = filteredAppRoutes.filter(
                (route) => route.path !== `/dashboard/gift-voucher`
              );
            }
          }
        );

        Promise.all([
          await dispatch(myPrepaidSaleItems()),
          await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_PREPAID })),
        ]).then(([myPrepaidSaleItemsRes, prepaidSaleItemsRes]) => {
          if (
            !myPrepaidSaleItemsRes.payload.length &&
            !prepaidSaleItemsRes.payload.data.length
          ) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/webshop`
            );
          }
        });

        await dispatch(getIdentificationItemTypes()).then(({ payload }) => {
          filteredAppRoutes = filteredAppRoutes.filter(
            (route) =>
              !route.code ||
              payload.some((el) => el.code === route.code) ||
              payload.some((el) => route.code.includes(el.code))
          );
        });
      }

      setAppRoutes(() => {
        return {
          ...applicationRoutes,
          dashboard: filteredAppRoutes,
        };
      });

      setSidebarRender(true);
    })();
  }, [accountStatus]);

  useEffect(() => {
    (async () => {
      if (isAdded && accountStatus === "COMPLETED") {
        let filteredAppRoutes = applicationRoutes.dashboard;

        await dispatch(myCustomerInvoiceData()).then(({ payload }) => {
          if (!payload) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/invoice`
            );
          } else {
            const inVoiceRoute = filteredAppRoutes.find(
              (route) => route.path === `/dashboard/invoice`
            );
            inVoiceRoute.visibleOnSidebar = true;
          }
        });

        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_CHARGE })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/credit`
            );
          }
        });

        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_SUBSCRIPTIONS })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/subscriptions`
            );
          }
        });

        await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_GIFT })).then(
          ({ payload }) => {
            if (!payload.data.length) {
              filteredAppRoutes = filteredAppRoutes.filter(
                (route) => route.path !== `/dashboard/gift-voucher`
              );
            }
          }
        );

        Promise.all([
          await dispatch(myPrepaidSaleItems()),
          await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_PREPAID })),
        ]).then(([myPrepaidSaleItemsRes, prepaidSaleItemsRes]) => {
          if (
            !myPrepaidSaleItemsRes.payload.length &&
            !prepaidSaleItemsRes.payload.data.length
          ) {
            filteredAppRoutes = filteredAppRoutes.filter(
              (route) => route.path !== `/dashboard/webshop`
            );
          }
        });

        await dispatch(getIdentificationItemTypes()).then(({ payload }) => {
          filteredAppRoutes = filteredAppRoutes.filter(
            (route) =>
              !route.code ||
              payload.some((el) => el.code === route.code) ||
              payload.some((el) => route.code.includes(el.code))
          );
        });

        setAppRoutes(() => {
          return {
            ...applicationRoutes,
            dashboard: filteredAppRoutes,
          };
        });
      }
    })();
  }, [isAdded, accountStatus]);

  return {
    applicationRoutes: appRoutes,
    sidebarRender,
    setSidebarRender,
  };
};
export default useApplicationRoutes;

import { func } from "prop-types";
import { forwardRef } from "react";
import { Title } from "@components/Title";
import { AnonymousNavigation } from "@components/AnonymousNavigation";

const AnonymousHeader = forwardRef(
  ({ toggleSidebar, applicationRoutes, sidebarHidden }, ref) => {
    return (
      <header className="nav-sidebar" ref={ref}>
        <AnonymousNavigation
          toggleSidebar={toggleSidebar}
          sidebarHidden={sidebarHidden}
        />
        <Title applicationRoutes={applicationRoutes} />
      </header>
    );
  }
);

AnonymousHeader.propTypes = {
  toggleSidebar: func.isRequired,
};

export default AnonymousHeader;

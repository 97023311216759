import { getPreSale, processPreSale } from "@store/reducers/multiSafepay";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { RiLoaderFill } from "react-icons/ri";
import { myBalance } from "@store/reducers/profile";

const Status = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isStatusPageVisible, setIsStatusPageVisible] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSuccessfulProcessed, setIsSuccessfulProcessed] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");

  const processMultiSafepay = () => {
    const querys = Object.fromEntries(new URLSearchParams(location.search));
    dispatch(processPreSale(querys.transactionid));
  };

  useEffect(() => {
    const querys = Object.fromEntries(new URLSearchParams(location.search));
    if (location.pathname === "/multisafepay/cancelled") {
      setIsCancelled(true);
    } else {
      setTimeout(() => {
        dispatch(getPreSale(querys.transactionid)).then(function ({
          payload: { successfulProcessed, paymentStatus },
        }) {
          if (successfulProcessed) {
            dispatch(myBalance());
          }
          setIsSuccessfulProcessed(successfulProcessed);
          setPaymentStatus(paymentStatus);
          setIsStatusPageVisible(true);
        });
      }, 5000);
    }
  }, []);

  return (
    <Row className={"mx-0"}>
      <Col className="mt-3" md="12">
        <Card>
          <CardBody>
            {isCancelled ? (
              <>
                <CardTitle className="mb-3">
                  <FormattedMessage
                    id={`multisafepay.payment.cancelled.title`}
                  />
                </CardTitle>
                <span>
                  <FormattedMessage
                    id={`multisafepay.payment.cancelled.description`}
                  />
                </span>
              </>
            ) : (
              <>
                {!isStatusPageVisible ? (
                  <>
                    <RiLoaderFill />{" "}
                    <FormattedMessage id={`multisafepay.processing`} />
                  </>
                ) : (
                  <>
                    {isSuccessfulProcessed ? (
                      <>
                        <CardTitle className="mb-3">
                          <FormattedMessage
                            id={`multisafepay.payment.success.title`}
                          />
                        </CardTitle>
                        <span>
                          <FormattedMessage
                            id={`multisafepay.payment.success.description`}
                          />
                        </span>
                      </>
                    ) : paymentStatus === "completed" ||
                      paymentStatus === "shipped" ? (
                      <>
                        {/* Only for development reasons */}
                        {process.env.NODE_ENV !== "production" && (
                          <Button color="primary" onClick={processMultiSafepay}>
                            process transaction
                          </Button>
                        )}
                        <CardTitle className="mb-3">
                          <FormattedMessage
                            id={`multisafepay.payment.processing.title`}
                          />
                        </CardTitle>
                        <span>
                          <FormattedMessage
                            id={`multisafepay.payment.processing.description`}
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        {/* Only for development reasons */}
                        {process.env.NODE_ENV !== "production" && (
                          <Button color="primary" onClick={processMultiSafepay}>
                            process transaction
                          </Button>
                        )}
                        <CardTitle className="mb-3">
                          <FormattedMessage
                            id={`multisafepay.payment.unsuccessful.title`}
                          />
                        </CardTitle>
                        <span>
                          <FormattedMessage
                            id={`multisafepay.payment.unsuccessful.description`}
                          />
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Status;

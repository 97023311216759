import { string } from "prop-types";
import { useState } from "react";
import { Tooltip as TooltipRs } from "reactstrap";

const Tooltip = ({ children, tooltipId, content, placement }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {children}
      <TooltipRs
        placement={placement ? placement : "left"}
        isOpen={tooltipOpen}
        target={`${tooltipId}`}
        toggle={toggle}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </TooltipRs>
    </>
  );
};

Tooltip.propTypes = {
  content: string.isRequired,
  tooltipId: string.isRequired,
};

export default Tooltip;

import { useDispatch, useSelector } from "react-redux";
import { getSystemImagesAction } from "@store/reducers/systemImages";

const useSystemImages = () => {
  const dispatch = useDispatch();
  const { systemImages, loading } = useSelector((state) => state?.systemImages);

  const getSystemImages = async (data) => {
    await dispatch(getSystemImagesAction({ code: data }));
  };

  return {
    systemImages,
    loading,
    getSystemImages,
  };
};

export default useSystemImages;

import useMultiSafepay from "@hooks/useMultiSafepay";

const Payment = ({
  identificationItemId,
  saleItemId,
  salePrepaidItem,
  reservationId,
  saleItemPrice,
  saleItemQty,
  salePaymentType,
}) => {
  const { render, multiSafepay } = useMultiSafepay({
    saleItemId,
    salePrepaidItem,
    reservationId,
    saleItemPrice,
    saleItemQty,
    identificationItemId,
    salePaymentType,
  });

  if (
    !render &&
    multiSafepay.multiSafepayUrl != null &&
    multiSafepay.multiSafepayUrl !== ""
  )
    window.location.replace(multiSafepay.multiSafepayUrl);

  return null;
};

export default Payment;

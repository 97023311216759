import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes } from "@utils";
import { apiAction } from "@utils/http";

const reducerName = "adyen";

export const getPaymentMethodsActions = createAsyncThunk(
  `${reducerName}/get-payment-methods`,
  async (data, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentAdyen.getPaymentMethods,
        data,
      });

      const {
        clientKey,
        environment,
        recurringPayment,
        recurringPaymentMethods,
        recurringPaymentMethodCardBrands,
        paymentMethodsResponse,
        amountValue,
      } = response.data;

      return {
        clientKey,
        environment,
        recurringPayment,
        recurringPaymentMethods,
        recurringPaymentMethodCardBrands,
        paymentMethodsResponse,
        amountValue,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const makePaymentAction = createAsyncThunk(
  `${reducerName}/make-payment`,
  async (body = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentAdyen.makePayment,
        data: body,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const submitAdditionalPaymentDetailsAction = createAsyncThunk(
  `${reducerName}/submit-additional-payment-details`,
  async (body = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentAdyen.submitAdditionalPaymentDetails,
        data: body,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

/**
 * A shopper may request to delete their saved details for a certain payment method
 * @type {AsyncThunk<unknown, void, {}>}
 */
export const disableStoredPaymentDetailsAction = createAsyncThunk(
  `${reducerName}/disable-stored-payment-details`,
  async (body = {}, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.salePaymentAdyen.disableStoredPaymentDetails,
        data: body,
      });

      return data.response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const slice = createSlice({
  name: "adyen",
  initialState: {
    isLoading: false,
    error: "",
    paymentMethodsRes: null,
    paymentRes: null,
    paymentDetailsRes: {
      resultCode: null,
    },
    dropInConfig: {
      openFirstPaymentMethod: false,
      showStoredPaymentMethods: false,
    },
    checkoutConfig: {
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        bcmc: {
          amount: {
            value: 0, // 10€ in minor units
            currency: "EUR",
          },
        },
        card: {
          brands: ["bcmc", "mc", "visa", "amex"], // https://docs.adyen.com/payment-methods/cards/custom-card-integration#supported-card-types
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or debit card",
          amount: {
            value: 0, // 10€ in minor units
            currency: "EUR",
          },
        },
      },
      allowPaymentMethods: [], // ["bcmc", "ideal", "scheme"]
      locale: process.env.REACT_APP_DEFAULT_LOCALE,
      environment: "test",
      clientKey: process.env.REACT_APP_CLIENT_KEY,
      recurringPayment: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethodsActions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPaymentMethodsActions.fulfilled,
      (
        state,
        {
          payload: {
            clientKey,
            environment,
            paymentMethodsResponse,
            recurringPaymentMethods,
            recurringPaymentMethodCardBrands,
            recurringPayment,
            amountValue,
          },
        }
      ) => {
        state.isLoading = false;
        state.checkoutConfig.environment = environment;
        state.checkoutConfig.clientKey = clientKey;
        state.paymentMethodsRes = paymentMethodsResponse;
        state.recurringPayment = recurringPayment;
        if (recurringPaymentMethods) {
          state.checkoutConfig.allowPaymentMethods = recurringPaymentMethods;
        } else {
          state.checkoutConfig.allowPaymentMethods = [];
        }
        if (recurringPaymentMethodCardBrands) {
          state.checkoutConfig.paymentMethodsConfiguration.card.brands =
            recurringPaymentMethodCardBrands;
        } else {
          state.checkoutConfig.paymentMethodsConfiguration.card.brands = [
            "bcmc",
            "mc",
            "visa",
            "amex",
          ];
        }

        state.checkoutConfig.paymentMethodsConfiguration.bcmc.amount.value =
          amountValue;
        state.checkoutConfig.paymentMethodsConfiguration.card.amount.value =
          amountValue;
      }
    );
    builder.addCase(makePaymentAction.fulfilled, (state, { payload }) => {
      state.paymentRes = payload;
    });
    builder.addCase(
      submitAdditionalPaymentDetailsAction.fulfilled,
      (state, { payload }) => {
        state.paymentDetailsRes = payload;
      }
    );
  },
});

export default slice.reducer;

import { Suspense } from "react";
import { Route } from "react-router-dom";
import { applicationRoutes } from "@utils";
import { Spinner } from "@components/Spinner";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";

const AuthRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <LocalizedSwitch>
        {applicationRoutes.auth.map((route) => (
          <Route {...route} key={route.path} />
        ))}
      </LocalizedSwitch>
    </Suspense>
  );
};

export default AuthRoutes;

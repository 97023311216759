import useShouldShowPrompt from "@hooks/useShouldShowPrompt";

const iosInstallPromptedAt = "iosInstallPromptedAt";

const isIOS = () => {
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  const ua = window.navigator.userAgent;

  const isIPad = !!ua.match(/iPad/i) && ua.match(/Version\/[\d.]+.*Safari/);
  const isIPhone = !!ua.match(/iPhone/i) && ua.match(/Version\/[\d.]+.*Safari/);

  return isIPad || isIPhone;
};

const useIosInstallPrompt = () => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt);

  return [
    isIOS() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
export default useIosInstallPrompt;

import { Spinner } from "@components/Spinner";
import { useState } from "react";
import { useParams } from "react-router-dom";

const PreviewPrepaidSaleItem = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);

  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <>
      {loading ? <Spinner /> : null}
      <iframe
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        width="100%"
        height="100%"
        style={{ position: "fixed", minHeight: "100vh" }}
        onLoad={hideSpinner}
        scrolling="auto"
        src={`${process.env.REACT_APP_BASE_URL}/prepaid-sale-items/${uuid}`}
      />
    </>
  );
};

export default PreviewPrepaidSaleItem;

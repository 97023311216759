import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { handleLogout } from "@store/reducers/auth";
import Storage from "@utils/storage";
import { useIntl } from "react-intl";
const useNavigation = (props) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.profile.account);
  const { balance } = useSelector((state) => state.profile);
  const { logo } = useSelector((state) => state.theme.images);
  const { locale } = useIntl();

  const logout = async () => {
    try {
      await dispatch(handleLogout());
      await Storage.clear();
      window.location.href = `/${locale}/auth/login`;
    } catch (e) {}
  };

  return {
    account,
    balance,
    logo,
    logout,
  };
};
export default useNavigation;
